import React from 'react'
import styled, { css } from 'styled-components'
import mountEverest from '../assets/images/mount_everest.png'

const BackgroundContainer = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
`

const BackgroundWrapper = styled.div`
    position: relative;
    max-width: 1880px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`

const CustomImage: any = styled.img`
    position: absolute;
    z-index: 1;
    height: 80vh;
    width: auto;
    object-fit: contain;
    left: unset;
    right: -40vh;
    bottom: -10vh;
    user-select: none;

    @media (max-width: 768px) {
        display: none;
    }

    ${(props: any) => props.flip && css`
        transform: scaleX(-1);
        left: -40vh;
        right: unset;
    `}
`

export default function Background() {
    return (
        <BackgroundContainer>
            <BackgroundWrapper>
                <CustomImage src={mountEverest} />
                <CustomImage src={mountEverest} flip={true} />
            </BackgroundWrapper>
        </BackgroundContainer>
    )
}