import Logo from 'src/assets/images/logo.svg'

export interface CHAIN {
  name: string
  symbol: string
  logo: string
}

export enum ChainsId {
  All = 0,
  ICE = 550,
  SNOW = 552,
  ARCTIC = 553
}

export const CHAINS = {
  [ChainsId.All]: {
    name: 'All Chains',
    symbol: 'All',
    logo: Logo
  },
  [ChainsId.ICE]: {
    name: 'ICE',
    symbol: 'ICY',
    logo: 'https://static.debank.com/image/chain/logo_url/eth/42ba589cd077e7bdd97db6480b0ff61d.png',
    tracked_by_debank: false,
    supported_by_gelato: false,
    coingecko_id: '', // TODO: add coingecko ID
    is_mainnet: true,
    airdrop_active: false
  },
  [ChainsId.SNOW]: {
    name: 'Snow',
    symbol: 'ICZ',
    logo: 'https://static.debank.com/image/chain/logo_url/eth/42ba589cd077e7bdd97db6480b0ff61d.png',
    tracked_by_debank: false,
    supported_by_gelato: false,
    coingecko_id: '', // TODO: add coingecko ID
    is_mainnet: false,
    airdrop_active: false
  },
  [ChainsId.ARCTIC]: {
    name: 'Arctic',
    symbol: 'ICZ',
    logo: 'https://static.debank.com/image/chain/logo_url/eth/42ba589cd077e7bdd97db6480b0ff61d.png',
    tracked_by_debank: false,
    supported_by_gelato: false,
    coingecko_id: '', // TODO: add coingecko ID
    is_mainnet: false,
    airdrop_active: false
  }
}
