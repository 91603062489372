import { TokenAmount, JSBI, ChainId } from '@everestswap/sdk'
import React, { useMemo, useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import tokenLogo from '../../assets/images/token-logo.png'
import { injected } from '../../connectors'
import { CHAINS } from '../../constants/chains'
import { getTokenLogoURL, EVEREST_API_BASE_URL } from '../../constants'
import { EVRS } from '../../constants/tokens'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { useTotalEvrsEarned } from '../../state/stake/hooks'
import { DOUBLE_SIDE_STAKING_REWARDS_CURRENT_VERSION } from '../../state/stake/doubleSideConfig'
import { useAggregateEvrsBalance, useTokenBalance } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE, EvrsTokenAnimated } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { Break, CardBGImage, CardNoise, CardSection, DataCard } from '../earn/styled'
import { useTranslation } from 'react-i18next'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { useChainId } from 'src/hooks'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const ModalUpper = styled(DataCard)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #07c1ef 0%, #07e3ef 100%);
  padding: 0.5rem;
`

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`
const AddEVRS = styled.span`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 32;
  padding: 4px 6px;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #07c1ef 0%, #07e3ef 100%), #edeef2;
  border-radius: 12px;
  white-space: nowrap;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

/**
 * Content for balance stats modal
 */
export default function EvrsBalanceContent({ setShowEvrsBalanceModal }: { setShowEvrsBalanceModal: any }) {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const evrs = chainId ? EVRS[chainId] : undefined

  const total = useAggregateEvrsBalance()
  const evrsBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, evrs)
  const evrsToClaim: TokenAmount | undefined = useTotalEvrsEarned()

  const totalSupply: TokenAmount | undefined = useTotalSupply(evrs)

  // Determine EVRS price in ICZ
  const oneToken = JSBI.BigInt(1000000000000000000)
  const { t }: { t: any } = useTranslation()

  const usdcPriceTmp = useUSDCPrice(evrs)
  const usdcPrice = CHAINS[chainId].is_mainnet ? usdcPriceTmp : undefined

  let evrsPrice

  if (usdcPrice && evrs) {
    evrsPrice = usdcPrice.quote(new TokenAmount(evrs, oneToken), chainId)
  }

  const [circulation, setCirculation] = useState(totalSupply)

  useMemo(() => {
    if (evrs === undefined) return
    fetch(`${EVEREST_API_BASE_URL}/png/circulating-supply`)
      .then(res => res.text())
      .then(val => setCirculation(new TokenAmount(evrs, val)))
  }, [evrs])

  return (
    <ContentWrapper gap="lg">
      <ModalUpper>
        <CardBGImage />
        <CardNoise />
        <CardSection gap="md">
          <RowBetween>
            <TYPE.white color="white">{t('header.evrsBreakDown')}</TYPE.white>
            <StyledClose stroke="white" onClick={() => setShowEvrsBalanceModal(false)} />
          </RowBetween>
        </CardSection>
        <Break />
        {account && (
          <>
            <CardSection gap="sm">
              <AutoColumn gap="md" justify="center">
                <EvrsTokenAnimated width="48px" src={tokenLogo} />{' '}
                <TYPE.white fontSize={48} fontWeight={600} color="white">
                  {total?.toFixed(2, { groupSeparator: ',' })}
                </TYPE.white>
              </AutoColumn>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white color="white">{t('header.balance')}</TYPE.white>
                  <TYPE.white color="white">{evrsBalance?.toFixed(2, { groupSeparator: ',' })}</TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white color="white">{t('header.unclaimed')}</TYPE.white>
                  <TYPE.white color="white">
                    {evrsToClaim?.toFixed(4, { groupSeparator: ',' })}{' '}
                    {evrsToClaim && evrsToClaim.greaterThan('0') && (
                      <StyledInternalLink
                        onClick={() => setShowEvrsBalanceModal(false)}
                        to={`/evrs/${DOUBLE_SIDE_STAKING_REWARDS_CURRENT_VERSION}`}
                      >
                        ({t('earn.claim')})
                      </StyledInternalLink>
                    )}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
            <Break />
          </>
        )}
        <CardSection gap="sm">
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.white color="white">{t('header.evrsPrice')}</TYPE.white>
              <TYPE.white color="white">${evrsPrice?.toFixed(2, { groupSeparator: ',' }) ?? '-'}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">{t('header.evrsCirculation')}</TYPE.white>
              <TYPE.white color="white">{circulation?.toFixed(0, { groupSeparator: ',' }) ?? '-'}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">{t('header.totalSupply')}</TYPE.white>
              <TYPE.white color="white">{totalSupply?.toFixed(0, { groupSeparator: ',' }) ?? '-'}</TYPE.white>
            </RowBetween>
          </AutoColumn>
        </CardSection>
        {account && (
          <>
            <CardSection gap="sm">
              <AutoColumn gap="md">
                <AddEVRS
                  onClick={() => {
                    injected.getProvider().then(provider => {
                      if (provider) {
                        provider
                          .request({
                            method: 'wallet_watchAsset',
                            params: {
                              type: 'ERC20',
                              options: {
                                address: evrs?.address,
                                symbol: evrs?.symbol,
                                decimals: evrs?.decimals,
                                image: getTokenLogoURL(EVRS[ChainId.ICE_MAINNET].address, 48)
                              }
                            }
                          })
                          .catch((error: any) => {
                            console.error(error)
                          })
                      }
                    })
                  }}
                >
                  <TYPE.white color="white">{t('header.addMetamask')}</TYPE.white>
                </AddEVRS>
              </AutoColumn>
            </CardSection>
          </>
        )}
      </ModalUpper>
    </ContentWrapper>
  )
}
