import { ChainId, Token, contractAddresses } from '@everestswap/sdk'

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// TODO: replace addresses with real contracts and add listed tokens

export const EVRS: { [chainId in ChainId]: Token } = {
  [ChainId.ICE_ARCTIC]: new Token(ChainId.ICE_ARCTIC, contractAddresses.ICE_ARCTIC.TOKEN, 18, 'EVRS', 'Everest'),
  [ChainId.ICE_SNOW]: new Token(ChainId.ICE_SNOW, contractAddresses.ICE_SNOW.TOKEN, 18, 'EVRS', 'Everest'),
  [ChainId.ICE_MAINNET]: new Token(ChainId.ICE_MAINNET, contractAddresses.ICE_MAINNET.TOKEN, 18, 'EVRS', 'Everest')
}

export const bnUSD: { [chainId in ChainId]: Token } = {
  [ChainId.ICE_ARCTIC]: new Token(ChainId.ICE_ARCTIC, '0xD89e434e69Ce6Ec59659EC719750Ea2456861220', 18, 'bnUSD', 'Balanced Dollars'),
  [ChainId.ICE_SNOW]: new Token(ChainId.ICE_SNOW, '0x93ea705B67eD6bdbd4A72657Ea8c767f50E3Db9e', 18, 'bnUSD', 'Balanced Dollars'),
  [ChainId.ICE_MAINNET]: new Token(ChainId.ICE_MAINNET, ZERO_ADDRESS, 18, 'bnUSD', 'Balanced Dollars')
}

export const sICX: { [chainId in ChainId]: Token } = {
  [ChainId.ICE_ARCTIC]: new Token(ChainId.ICE_ARCTIC, '0x3479B6c40739a56A078eC0b61E36c91e94Aef3A6', 18, 'sICX', 'Staked ICX'),
  [ChainId.ICE_SNOW]: new Token(ChainId.ICE_SNOW, '0x9c93CeA18Ee49Ea279Be5832Dc389Bb9B8AFB7dC', 18, 'sICX', 'Staked ICX'),
  [ChainId.ICE_MAINNET]: new Token(ChainId.ICE_MAINNET, ZERO_ADDRESS, 18, 'sICX', 'Staked ICX')
}

export const ICX: { [chainId in ChainId]: Token } = {
  [ChainId.ICE_ARCTIC]: new Token(ChainId.ICE_ARCTIC, '0x63c1162F92E6cae9f8da1308C896b6e8334300c5', 18, 'ICX', 'Icon'),
  [ChainId.ICE_SNOW]: new Token(ChainId.ICE_SNOW, '0x1106e77ec895C5f246159e89f7d62F79898eF88d', 18, 'ICX', 'Icon'),
  [ChainId.ICE_MAINNET]: new Token(ChainId.ICE_MAINNET, ZERO_ADDRESS, 18, 'ICX', 'Icon')
}
