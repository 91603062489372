import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import 'inter-ui'
import React, { StrictMode } from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { NetworkContextName } from './constants'
import './i18n'
import App from './pages/App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import store from './state'
import UserUpdater from './state/user/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import { EverestProvider } from '@everestswap/components'
import getLibrary from './utils/getLibrary'
import { useTheme } from 'styled-components'
import { useActiveWeb3React } from './hooks'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import Package from '../package.json'

// TODO: adapt with new creds
// Sentry.init({
//   dsn: '',
//   integrations: [new Integrations.BrowserTracing()],
//   release: `everest-interface@${Package.version}`, //manual for now
//   tracesSampleRate: 0.4,
//   allowUrls: ['https://everestswap.exchange'],
//   enabled: process.env.NODE_ENV === 'production',
//   ignoreErrors: [
//     'ResizeObserver loop limit exceeded',
//     'Blocked a frame with origin "https://everestswap.exchange" from accessing a cross-origin frame.'
//   ]
// })

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
}

const queryClient = new QueryClient()

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const ComponentThemeProvider = () => {
  const theme = useTheme()
  const { library, chainId, account } = useActiveWeb3React()
  return (
    <EverestProvider library={library} chainId={chainId} account={account ?? undefined} theme={theme as any}>
      <FixedGlobalStyle />
      <ThemedGlobalStyle />
      <HashRouter>
        <App />
      </HashRouter>
    </EverestProvider>
  )
}

ReactDOM.render(
  <StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Updaters />
            <ThemeProvider>
              <ComponentThemeProvider />
            </ThemeProvider>
          </QueryClientProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </StrictMode>,
  document.getElementById('root')
)
