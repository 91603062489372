import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@pangolindex/web3-react-injected-connector'
import { HanaConnector } from '@everestswap/react-injected-hana'
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkConnector } from './NetworkConnector'
import { ChainId, CHAINS } from '@everestswap/sdk'

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '552')

export const network = new NetworkConnector({
  urls: {
    [ChainId.ICE_ARCTIC]: CHAINS[ChainId.ICE_ARCTIC].rpc_uri,
    [ChainId.ICE_SNOW]: CHAINS[ChainId.ICE_SNOW].rpc_uri,
    [ChainId.ICE_MAINNET]: CHAINS[ChainId.ICE_MAINNET].rpc_uri,
  },
  defaultChainId: NETWORK_CHAIN_ID
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.ICE_ARCTIC, ChainId.ICE_SNOW, ChainId.ICE_MAINNET]
})

export const injectedHana = new HanaConnector({
  supportedChainIds: [ChainId.ICE_ARCTIC, ChainId.ICE_SNOW, ChainId.ICE_MAINNET]
})

export const gnosisSafe = new SafeAppConnector({
  supportedChainIds: [ChainId.ICE_ARCTIC, ChainId.ICE_SNOW, ChainId.ICE_MAINNET]
})

export const walletconnect = new WalletConnectConnector({
  rpc: {
    552: CHAINS[ChainId.ICE_SNOW].rpc_uri
  },
  qrcode: true,
  bridge: 'https://bridge.everestswap.exchange/'
})
