import { Interface } from '@ethersproject/abi'
import { ChainId, contractAddresses } from '@everestswap/sdk'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.ICE_ARCTIC]: contractAddresses.ICE_ARCTIC.FACTORY,
  [ChainId.ICE_SNOW]: contractAddresses.ICE_SNOW.FACTORY,
  [ChainId.ICE_MAINNET]: contractAddresses.ICE_MAINNET.FACTORY
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
