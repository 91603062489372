import { ChainId, WICZ } from '@everestswap/sdk'
import { MINICHEF_ADDRESS } from '../../constants'
import { EVRS } from '../../constants/tokens'
import { DoubleSideStaking } from './hooks'

// TODO: populate with listed tokens
export const DOUBLE_SIDE_STAKING: { [key: string]: DoubleSideStaking } = {
  WICZ_EVRS_V2: {
    tokens: [WICZ[ChainId.ICE_ARCTIC], EVRS[ChainId.ICE_ARCTIC]],
    stakingRewardAddress: MINICHEF_ADDRESS[ChainId.ICE_ARCTIC],
    version: 2
  }
}

export const DOUBLE_SIDE_STAKING_REWARDS_CURRENT_VERSION = Math.max(
  ...Object.values(DOUBLE_SIDE_STAKING).map(staking => staking.version)
)

export const DOUBLE_SIDE_STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: DoubleSideStaking[][]
} = {
  [ChainId.ICE_ARCTIC]: [], // TODO: setup staking rewards for arctic
  [ChainId.ICE_SNOW]: [], // TODO: setup staking rewards for snow
  [ChainId.ICE_MAINNET]: [Object.values(DOUBLE_SIDE_STAKING)]
}
