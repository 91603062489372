import { ChainId, JSBI, Percent, Token, WICZ, contractAddresses } from '@everestswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, injectedHana, walletconnect } from '../connectors'
import { bnUSD, sICX } from './tokens'

export const GAS_PRICE = 225

export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.ICE_ARCTIC]: contractAddresses.ICE_ARCTIC.ROUTER,
  [ChainId.ICE_SNOW]: contractAddresses.ICE_SNOW.ROUTER,
  [ChainId.ICE_MAINNET]: contractAddresses.ICE_MAINNET.ROUTER
}

export const ANALYTICS_PAGE = '' // TODO: change to new enpoints

export const EVEREST_API_BASE_URL = '' // TODO: change to new enpoints

export const EVEREST_TOKENS_REPO_RAW_BASE_URL = `https://raw.githubusercontent.com/everestswap/tokenlist`

export type LogoSize = 24 | 48
export const getTokenLogoURL = (address: string, size: LogoSize = 24) => `${EVEREST_TOKENS_REPO_RAW_BASE_URL}/main/assets/${address}/logo_${size}.png`

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const GOVERNANCE_ADDRESS = '0xb0Ff2b1047d9E8d294c2eD798faE3fA817F43Ee1'

export const MINICHEF_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.ICE_ARCTIC]: contractAddresses.ICE_ARCTIC.CHEF,
  [ChainId.ICE_SNOW]: contractAddresses.ICE_SNOW.CHEF,
  [ChainId.ICE_MAINNET]: contractAddresses.ICE_MAINNET.CHEF
}

export const NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const AIRDROP_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.ICE_ARCTIC]: contractAddresses.ICE_ARCTIC.AIRDROP,
  [ChainId.ICE_SNOW]: contractAddresses.ICE_SNOW.AIRDROP,
  [ChainId.ICE_MAINNET]: contractAddresses.ICE_MAINNET.AIRDROP
}

const WICZ_AND_EVRS_ONLY: ChainTokenList = {
  [ChainId.ICE_ARCTIC]: [WICZ[ChainId.ICE_ARCTIC]],
  [ChainId.ICE_SNOW]: [WICZ[ChainId.ICE_SNOW]],
  [ChainId.ICE_MAINNET]: [WICZ[ChainId.ICE_MAINNET]]
}

// used to construct intermediary pairs for trading
// TODO: use this when stables are available
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.ICE_ARCTIC]: [WICZ[ChainId.ICE_ARCTIC]],
  [ChainId.ICE_SNOW]: [
    WICZ[ChainId.ICE_SNOW],
    bnUSD[ChainId.ICE_SNOW],
    sICX[ChainId.ICE_SNOW],
  ],
  [ChainId.ICE_MAINNET]: [WICZ[ChainId.ICE_MAINNET]],
  // [ChainId.ICE_MAINNET]: [
  //   WICZ[ChainId.ICE_MAINNET],
  //   EVRS[ChainId.ICE_MAINNET],
  //   USDTe[ChainId.ICE_MAINNET],
  //   DAIe[ChainId.ICE_MAINNET],
  //   USDCe[ChainId.ICE_MAINNET],
  //   UST[ChainId.ICE_MAINNET],
  //   USDC[ChainId.ICE_MAINNET]
  // ]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.ICE_MAINNET]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WICZ_AND_EVRS_ONLY
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WICZ_AND_EVRS_ONLY
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ICE_MAINNET]: []
}

// these tokens can be directly linked to (via url params) in the swap page without prompting a warning
export const TRUSTED_TOKEN_ADDRESSES: { readonly [chainId in ChainId]: string[] } = {
  [ChainId.ICE_MAINNET]: [WICZ[ChainId.ICE_MAINNET].address],
  [ChainId.ICE_ARCTIC]: [WICZ[ChainId.ICE_ARCTIC].address],
  [ChainId.ICE_SNOW]: [WICZ[ChainId.ICE_SNOW].address]
}

export const SWAP_DEFAULT_CURRENCY = {
  [ChainId.ICE_MAINNET]: {
    inputCurrency: 'ICY',
    outputCurrnecy: ''
  },
  [ChainId.ICE_SNOW]: {
    inputCurrency: 'ICZ',
    outputCurrnecy: ''
  },
  [ChainId.ICE_ARCTIC]: {
    inputCurrency: 'ICZ',
    outputCurrnecy: ''
  }
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  HANA: {
    connector: injectedHana,
    name: 'Hana Wallet',
    iconName: 'hana-wallet.png',
    description: 'Multi-chain browser extension.',
    href: null,
    color: '#3308A8'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'Wallet Connect',
    iconName: 'walletConnectIcon.svg',
    description: 'Use Wallet Connect',
    href: null,
    color: '#315CF5'
  }
}

export const NetworkContextName = 'NETWORK'

export interface NetworkObject {
  chainId: string,
  chainName: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    decimals: number
  },
  rpcUrls: Array<string>,
  blockExplorerUrls: Array<string>,
}

export const CHAIN_PARAMS: { [chainId in ChainId]: NetworkObject } = {
  [ChainId.ICE_ARCTIC]: {
    chainId: '0x229',
    chainName: 'Arctic Testnet',
    nativeCurrency: {
      name: 'ICZ',
      symbol: 'ICZ',
      decimals: 18
    },
    rpcUrls: ['https://arctic-rpc.icenetwork.io:9933'],
    blockExplorerUrls: ['https://arctic.epirus.io/']
  },
  [ChainId.ICE_SNOW]: {
    chainId: '0x228',
    chainName: 'Snow Network',
    nativeCurrency: {
      name: 'ICE',
      symbol: 'ICZ',
      decimals: 18
    },
    rpcUrls: ['https://snow-rpc.icenetwork.io:9933'],
    blockExplorerUrls: ['https://snow.epirus.io/']
  },
  [ChainId.ICE_MAINNET]: {
    chainId: '0x226',
    chainName: 'ICE Network',
    nativeCurrency: {
      name: 'ICE',
      symbol: 'ICY',
      decimals: 18
    },
    rpcUrls: ['https://ice-rpc.icenetwork.io'],
    blockExplorerUrls: ['https://arctic.epirus.io/'] // TODO: change to real block explorer
  }
}

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 10 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 10 * 60

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_ONE = JSBI.BigInt(1)
export const BIG_INT_TWO = JSBI.BigInt(2)
export const BIG_INT_TEN = JSBI.BigInt(10)
export const BIG_INT_EIGHTEEN = JSBI.BigInt(18)
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)
export const ONE_TOKEN = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const IS_IN_IFRAME = window.parent !== window
export const TIMEFRAME = [
  {
    description: 'HOUR',
    label: '1H',
    interval: 60,
    momentIdentifier: 'hour'
  },
  {
    description: 'DAY',
    label: '1D',
    interval: 3600,
    momentIdentifier: 'day'
  },
  {
    description: 'WEEK',
    label: '1W',
    interval: 86400,
    momentIdentifier: 'week'
  },
  {
    description: 'MONTH',
    label: '1M',
    interval: 604800,
    momentIdentifier: 'month'
  },
  {
    description: 'YEAR',
    label: '1Y',
    interval: 2629746,
    momentIdentifier: 'year'
  },
  {
    description: 'ALL',
    label: 'ALL',
    interval: 2629746,
    momentIdentifier: ''
  }
]
