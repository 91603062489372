import React, { useRef } from 'react'
import { GitHub, Twitter, BookOpen, MessageCircle, Send } from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'

import { StyledMenu, StyledMenuButton, MenuFlyout, MenuItem } from '../StyledMenu'

import { useTranslation } from 'react-i18next'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const NarrowMenuFlyout = styled(MenuFlyout)`
  min-width: 8.125rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -17.25rem;
  `};
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  const { t }: { t: any } = useTranslation()
  useOnClickOutside(node, open ? toggle : undefined)

  // TODO: replace links with everest socials
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <NarrowMenuFlyout>
          <MenuItem id="link" href="https://twitter.com/everestswap">
            <Twitter size={14} />
            {t('menu.twitter')}
          </MenuItem>
          <MenuItem id="link" href="https://discord.gg/Ap9nEXM749">
            <MessageCircle size={14} />
            {t('menu.discord')}
          </MenuItem>
          <MenuItem id="link" href="https://t.me/+O2zyVGYB8oszNGM8">
            <Send size={14} />
            {t('menu.telegram')}
          </MenuItem>
          {/* <MenuItem id="link" href="">
            <MessageSquare size={14} />
            {t('header.forum')}
          </MenuItem>
          <MenuNavItem id="link" to={'/vote'}>
            <CheckSquare size={14} />
            {t('header.vote')}
          </MenuNavItem> */}
          <MenuItem id="link" href="https://github.com/everestswap">
            <GitHub size={14} />
            {t('menu.code')}
          </MenuItem>
          <MenuItem id="link" href="https://docs.everestswap.exchange">
            <BookOpen size={14} />
            {'Docs'}
          </MenuItem>
        </NarrowMenuFlyout>
      )}
    </StyledMenu>
  )
}
