import { ChainId, TokenAmount } from '@everestswap/sdk'
import React, { useState, useRef } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
// import { useLocation } from 'react-router'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Logo from '../../assets/images/logo-text.png'
// TODO: uncomment for network selector
// import { ReactComponent as DropDown } from '../../assets/images/dropdown-rounded.svg'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances, useAggregateEvrsBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { ExternalLink, TYPE } from '../../theme'
import { BlackCard } from '../Card'
import Settings from '../Settings'
// TODO: uncomment for network selector
// import { injected } from '../../connectors'
import Menu from '../Menu'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import Modal from '../Modal'
import EvrsBalanceContent from './EvrsBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import LanguageSelection from '../LanguageSelection'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Hidden } from 'src/theme'
import { useChainId } from 'src/hooks'
import { CHAIN_PARAMS } from 'src/constants'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`

const EVRSAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #07c1ef 0%, #07c1ef 100%), #edeef2;
`

const EVRSWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`

// TODO: add cursor pointer for network selector
const NetworkCard = styled(BlackCard)`
  position: relative;
  border-radius: 12px;
  padding: 8px 8px 8px 12px;
  white-space: nowrap;
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
  & > span {
    margin-right: 8px;
  }
  & > svg {
    width: 16px;
  }
  ${props => props.open && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  `}
`

// TODO: uncomment for network selector
// const NetworkSelector = styled(BlackCard)`
//   position: absolute;
//   top: 100%;
//   right: 0;
//   padding: 12px 0;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   border-bottom-left-radius: 12px;
//   border-bottom-right-radius: 12px;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 12px;
//     border-top-right-radius: 12px;
//   `}
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     top: initial;
//     bottom: 100%;
//   `}
// `

// TODO: uncomment for network selector
// const NetworkSelectorItem = styled(Text)`
//   cursor: pointer;
//   padding: 8px 12px;
//   :hover {
//     background-color: ${({ theme }) => theme.bg1};
//   }
// `

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const EvrsIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

// const StyledLink = styled.div<{ isActive: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text2)};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: ${({ isActive }) => (isActive ? 600 : 500)};
//   font-weight: 500;
//   line-height: 24px;

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }
// `

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

// const NarrowMenuFlyout = styled(MenuFlyout)`
//   min-width: 8.125rem;
//   left: 15rem;
//   right: auto !important;
// `

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.ICE_ARCTIC]: CHAIN_PARAMS[ChainId.ICE_ARCTIC].chainName,
  [ChainId.ICE_SNOW]: CHAIN_PARAMS[ChainId.ICE_SNOW].chainName,
  [ChainId.ICE_MAINNET]: CHAIN_PARAMS[ChainId.ICE_MAINNET].chainName
}

const NETWORK_CURRENCY: { [chainId in ChainId]?: string } = {
  [ChainId.ICE_ARCTIC]: CHAIN_PARAMS[ChainId.ICE_ARCTIC].nativeCurrency.symbol,
  [ChainId.ICE_SNOW]: CHAIN_PARAMS[ChainId.ICE_SNOW].nativeCurrency.symbol,
  [ChainId.ICE_MAINNET]: CHAIN_PARAMS[ChainId.ICE_MAINNET].nativeCurrency.symbol
}

export default function Header() {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()

  const { t }: { t: any } = useTranslation()

  // const location: any = useLocation()

  const userEthBalance = useETHBalances(chainId, account ? [account] : [])?.[account ?? '']

  const aggregateBalance: TokenAmount | undefined = useAggregateEvrsBalance()

  const [showEvrsBalanceModal, setShowEvrsBalanceModal] = useState(false)
  // TODO: uncomment for network selector
  // const [showNetworkModal, setShowNetworkModal] = useState(false)

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.FARM)
  const toggle = useToggleModal(ApplicationModal.FARM)
  useOnClickOutside(node, open ? toggle : undefined)

  // TODO: uncomment for network selector
  // const addICENetwork = (chainId: ChainId) => {
  //   injected.getProvider().then(provider => {
  //     provider
  //       ?.request({
  //         method: 'wallet_addEthereumChain',
  //         params: [CHAIN_PARAMS[chainId]]
  //       })
  //       .catch((error: any) => {
  //         console.log(error)
  //       })
  //   })
  // }

  return (
    <HeaderFrame>
      {process.env.REACT_APP_TOKEN_ENABLED === '1' ? (
        <Modal isOpen={showEvrsBalanceModal} onDismiss={() => setShowEvrsBalanceModal(false)}>
          <EvrsBalanceContent setShowEvrsBalanceModal={setShowEvrsBalanceModal} />
        </Modal>
      ) : null}
      <HeaderRow>
        <Title href=".">
          <EvrsIcon>
            <img height={'29px'} width={'auto'} style={{ marginRight: '25px' }} src={Logo} alt="logo" />
          </EvrsIcon>
        </Title>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('header.swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            {t('header.pool')}
          </StyledNavLink>

          <StyledExternalLink id={`bridge-nav-link`} href={'https://bridge.everestswap.exchange'}>
            {t('header.bridge')} <span style={{ fontSize: '11px', marginLeft: '5px', paddingTop: '5px' }}>↗</span>
          </StyledExternalLink>

          {/* <StyledLink
            id={`evrs-nav-link`}
            onClick={toggle}
            isActive={location?.pathname?.startsWith('/evrs')}
            ref={node as any}
          >
            {t('header.farm')} <ChevronDown size={24} />
            {open && (
              <NarrowMenuFlyout>
                <MenuNavItem id="link" to={'/evrs/1'}>
                  {t('header.version1')}
                </MenuNavItem>
                <MenuNavItem id="link" to={'/evrs/2'}>
                  {t('header.version2')}
                </MenuNavItem>
              </NarrowMenuFlyout>
            )}
          </StyledLink> */}

          {process.env.REACT_APP_TOKEN_ENABLED === '1' ? (
            <StyledNavLink id={`evrs-nav-link`} to={'/evrs'}>
              {t('header.farm')}
            </StyledNavLink>
          ) : null}

          {process.env.REACT_APP_TOKEN_ENABLED === '1' ? (
            <StyledNavLink
              id={`stake-nav-link`}
              to={'/stake/0'}
              isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/stake')}
            >
              {t('header.stake')}
            </StyledNavLink>
          ) : null}

          {process.env.REACT_APP_TOKEN_ENABLED === '1' ? (
            <StyledNavLink id={`evrs-nav-link`} to={'/vote'}>
              {t('header.vote')}
            </StyledNavLink>
          ) : null}

          {/* <StyledExternalLink id={`info-nav-link`} href={ANALYTICS_PAGE}>
            {t('header.charts')} <span style={{ fontSize: '11px' }}>↗</span>
          </StyledExternalLink> */}
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          {NETWORK_LABELS[chainId] ? (
            <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
          ) : null}
          {/*
          // TODO: uncomment for network selector
          <NetworkCard
            title={NETWORK_LABELS[chainId]}
            onMouseEnter={() => setShowNetworkModal(true)}
            onMouseLeave={() => setShowNetworkModal(false)}
            onClick={() => setShowNetworkModal(!showNetworkModal)}
            open={showNetworkModal}
          >
            <span>{NETWORK_LABELS[chainId]}</span>
            <DropDown style={{ transform: `rotate(${showNetworkModal ? '180deg' : '0'})` }} />
            {showNetworkModal && (
              <NetworkSelector>
                // TODO: remove filter when ICE release
                @ts-ignore
                {Object.keys(CHAIN_PARAMS).filter((v) => v !== '550' &&).map((network: ChainId, index: any) => (
                  <NetworkSelectorItem key={index} onClick={() => addICENetwork(network)}>
                    {NETWORK_LABELS[network]}
                  </NetworkSelectorItem>
                ))}
              </NetworkSelector>
            )}
          </NetworkCard> */}
          {(process.env.REACT_APP_TOKEN_ENABLED === '1' && aggregateBalance) ? (
            <EVRSWrapper onClick={() => setShowEvrsBalanceModal(true)}>
              <EVRSAmount active={!!account} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <Hidden upToSmall={true}>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </Hidden>
                )}
                EVRS
              </EVRSAmount>
              <CardNoise />
            </EVRSWrapper>
          ) : null}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} {NETWORK_CURRENCY[chainId]}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Settings />
          <LanguageSelection />
          <Menu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
